import firebase from "firebase/app";
import "firebase/firestore";

var firebaseConfig = {
  apiKey: "AIzaSyAogu3De_Cg7IkZ_LwpE8DlS7KgXg9bhoA",
  authDomain: "piyushpaliwal-85e99.firebaseapp.com",
  projectId: "piyushpaliwal-85e99",
  storageBucket: "piyushpaliwal-85e99.appspot.com",
  messagingSenderId: "674928403566",
  appId: "1:674928403566:web:5dbf49ac23ad1c38dcf27f",
  measurementId: "G-WCNJL9EWKS",
};

const fire = firebase.initializeApp(firebaseConfig);

var db = fire.firestore();

export { db };
export default fire;
