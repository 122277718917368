import React, { Component } from "react";
import styles from "./Cards.module.css";

class Cards extends Component {
  render() {
    return (
      <a href={this.props.cardLocation} className={styles.CardsPageContainer}>
        <img
          className={styles.CardImg}
          src={this.props.blogImg}
          alt="Error Displaying Img"
        ></img>
        <h1 className={styles.AboutCard}>
          <p className={styles.AboutCardHead}>{this.props.aboutArticleHead}</p>
          <p className={styles.AboutCardLines}>{this.props.aboutArticle}</p>
        </h1>
      </a>
    );
  }
}

export default Cards;
