import React, { Component } from "react";
import styles from "./Css/Blogs.module.css";
import Cards from "../Components/Cards";
import PeaCtfImg from "../Images/PeaCTF.png";
import dockerImage from "../Images/dockerimage.jpg";
import IntigritiLogo from "../Images/Intigriti-logo.jpg";
import comingSoonImg from "../Images/coming-soon.jpg";

class Blogs extends Component {
  render() {
    return (
      <div className={styles.BlogsPage} id="Blogs">
        <div className={styles.BlogsPageRow1}>
          <header className={styles.BlogsPageRow1Head}>
            Recent <b>Blogs</b>
          </header>
          <p className={styles.divider1}>*</p>
          <p className={styles.BlogsPageRow1Line}>
            Hola! I'm from India. Feel free to use the Contact form below to
            reach out to me, or drop me a Email.
          </p>
        </div>
        <p className={styles.Box}>
          <Cards
            cardLocation="https://piyush-paliwal.medium.com/peactf-2020-writeup-db712d327ef8"
            blogImg={PeaCtfImg}
            aboutArticleHead="PEA CTF"
            aboutArticle="This is Writeup of PEA CTF 2020 Begineers Challenge"
          />
          <Cards
            cardLocation="https://piyush-paliwal.medium.com/docker-ultimate-beginners-guide-step-by-step-7cc92948bd18"
            blogImg={dockerImage}
            aboutArticleHead="Docker"
            aboutArticle="Docker Ultimate Beginner’s Guide Step-by-Step | What, Why, How"
          />
          <Cards
            cardLocation="https://piyush-paliwal.medium.com/intigriti-0821-xss-writeup-478e80154bd5"
            blogImg={IntigritiLogo}
            aboutArticleHead="Intigriti Challenge"
            aboutArticle="This is Intigriti's August 2021 XSS Challenge Writeup"
          />
          <Cards
            cardLocation="#"
            blogImg={comingSoonImg}
            aboutArticleHead="Coming"
            aboutArticle="Soon"
          />
        </p>
      </div>
    );
  }
}

export default Blogs;
