import React, { Component } from "react";
import styles from "./Css/Contact.module.css";
import { BsPhone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { FiTwitter } from "react-icons/fi";
import Loader from "../Components/Loader";
import { db } from "../fire";
import emailjs from "emailjs-com";

class Contact extends Component {
  state = {
    name: "",
    email: "",
    phone: "",
    message: "",
    sent: false,
    loading: false,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ loading: true });

    db.collection("Contact")
      .add({
        Name: this.state.name,
        Email: this.state.email,
        Phone: this.state.phone,
        Message: this.state.message,
        Time: new Date(Date.now())
      })
      .then(() => {
        // alert("Message has been Submitted");
        this.setState({ sent: true, loading: false });
        setTimeout(
          function () {
            this.setState({ sent: false });
          }.bind(this),
          10000
        ); // wait 10 seconds, then reset to false
      });

    emailjs
      .sendForm(
        "service_nrwvana",
        "template_j1au6bk",
        e.target,
        "user_QgGZbaK2Hc30n8mvAwbsU"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    this.setState({
      name: "",
      email: "",
      phone: "",
      message: "",
    });
  };
  
  render() {
    return (
      <React.Fragment>
        {this.state.loading ? <Loader /> : ""}
        <div className={styles.ContactPage} id="Contact">
          <div className={styles.ContactPageColumn1}>
            <header className={styles.ContactPageColumn1head}>
              Get in <b>TUCH</b>
            </header>
            <p className={styles.divider1}>*</p>
            <p className={styles.ContactLines}>
              Hola! I'm from India. Feel free to use the Contact form below to
              reach out to me, or drop me a Email.
            </p>
          </div>
          <div className={styles.ContactPageColumn2}>
            <div className={styles.ContactOption}>
              <div className={styles.Option1}>
                <BsPhone className={styles.phoneicon} />
                <h4>Call me on</h4>
                <p>+91 875 851 0856</p>
              </div>
              <div className={styles.Option2}>
                <AiOutlineMail className={styles.emailicon} />
                <h4>Email me at</h4>
                <p>piyushthepal@gmail.com</p>
              </div>
              <div className={styles.Option3}>
                <FiTwitter className={styles.mapicon} />
                <h4>Message me at</h4>
                <p>@PiyushThePal</p>
              </div>
            </div>
          </div>
          <div className={styles.ContactPageColumn3}>
            <form className={styles.ContactForm} onSubmit={this.handleSubmit}>
              <label className={styles.ContactFormLabel}> Name </label>
              <input
                type="text"
                name="name"
                value={this.state.name}
                onChange={this.handleChange}
                placeholder="Your name..."
                required
              />
              <label className={styles.ContactFormLabel}> Mobile </label>
              <input
                type="number"
                name="phone"
                value={this.state.phone}
                onChange={this.handleChange}
                placeholder="Your mobile..."
                required
              />
              <label className={styles.ContactFormLabel}> Email </label>
              <input
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
                placeholder="Your email..."
                required
              />
              <label className={styles.ContactFormLabel}> Message </label>
              <textarea
                type="text"
                name="message"
                value={this.state.message}
                onChange={this.handleChange}
                placeholder="Your message..."
                required
              />
              <div className={styles.lastColumn}>
                <p
                  className={styles.successMessage}
                  style={
                    this.state.sent
                      ? { display: "inline" }
                      : { display: "none" }
                  }
                >
                  SENT
                </p>
                <button className={styles.ContactFormSendButton}>
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Contact;
