import React, { Component } from "react";
import styles from "./Skills.module.css";

class Skills extends Component {
  render() {
    return (
      <div>
        <div className={styles.columnbox}>
          <label className={styles.columnname}>{this.props.skillname}</label>
          <p className={styles.columnpercent}>{this.props.skillpercent}</p>
        </div>
        <span className={styles.percentbarbox}>
          <span
            className={styles.percentbar}
            style={{ width: this.props.skillpercent }}
          ></span>
        </span>
      </div>
    );
  }
}

export default Skills;
