import React, { Component } from "react";
import styles from "./Css/About.module.css";
import Skills from "../Components/Skills";

class About extends Component {
  render() {
    return (
      <div className={styles.AboutPage} id="About">
        <div className={styles.AboutPageColumn1}>
          <h1 className={styles.AboutPageColumn1head} style={{fontWeight: "normal"}}>
            <b>About</b> Me
          </h1>
          <p className={styles.divider1}>*</p>
          <p className={styles.AboutMeColumn1Lines}>
            I am a Part-time Hacker and a Front-End Developer. I instil
            confidence in others and approach new challenges with an open mind,
            and I use a creative approach to solve problems.
          </p>
        </div>
        <div className={styles.AboutPageColumn2}>
          <p className={styles.sign}>Piyush Paliwal</p>
          <p className={styles.AboutPageColumnTitle2}>
            Hello! <b>I'M Piyush Paliwal.</b>
            {/* <br></br> */}
          </p>
          <p className={styles.AboutPageColumnTags2}>
            A.K.A PiyushThePal
          </p>
          <p className={styles.AboutPageColumnTags2}>
            Hacker <span className={styles.dot}></span> Developer <span className={styles.dot}></span> CTF Player
          </p>
          <p className={styles.AboutMeColumn2Lines}>
            It’s been not a long time me working as an Ethical-Hacker. I have
            found some great bugs like IDOR, XSS, SQL, and many more. One of the
            most recent bugs I have discovered is SQL in one of the most popular
            website named OPPO. And this is just a Teaser. What about my
            Front-End Development? You can have a look at this website this is
            one of the websites made by me.
          </p>
        </div>
        <div className={styles.AboutPageColumn3}>
          <div className={styles.leftcolumn}>
            <div>
              <Skills skillname="Web Application Pentesting" skillpercent="90%" />
              <Skills skillname="API Pentesting" skillpercent="85%" />
              <Skills skillname="Network Pentesting" skillpercent="80%" />
            </div>
          </div>
          <div className={styles.rightcolumn}>
            <div>
              <Skills skillname="Forensics" skillpercent="70%" />
              <Skills skillname="Source-Code Auditing" skillpercent="80%" />
              <Skills skillname="Windows Active Directory Pentesting" skillpercent="70%" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
