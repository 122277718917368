import React, { Component } from "react";
import styles from "./MenuBar.module.css";
import WhiteLOGO from "../Images/logo.png";
import BlackLOGO from "../Images/logo-dark.png";
import { NavHashLink } from "react-router-hash-link";

class MenuBar extends Component {
  state = {
    navbar: false,
    logo: false,
  };

  listenScrollEvent = (e) => {
    if (window.scrollY > 300) {
      this.setState({ navbar: true, logo: true });
    } else {
      this.setState({ navbar: false, logo: false });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
  }

  render() {
    return (
      <div
        className={
          this.state.navbar ? styles.WhiteMenuBar : styles.TransMenuBar
        }
      >
        <NavHashLink smooth to="#Home" className={styles.logo}>
          <img
            className={styles.logo}
            src={this.state.logo ? BlackLOGO : WhiteLOGO}
            alt="Piyush"
          />
        </NavHashLink>

        <ul className={styles.MenuItems}>
          <li className={styles.MenuItem}>
            <NavHashLink
              smooth
              to="#Home"
              className={
                this.state.navbar ? styles.BlackMenuLink : styles.WhiteMenuLink
              }
            >
              Home
            </NavHashLink>
          </li>
          <li className={styles.MenuItem}>
            <NavHashLink
              smooth
              to="#About"
              className={
                this.state.navbar ? styles.BlackMenuLink : styles.WhiteMenuLink
              }
            >
              About
            </NavHashLink>
          </li>
          <li className={styles.MenuItem}>
            <NavHashLink
              smooth
              to="#Blogs"
              className={
                this.state.navbar ? styles.BlackMenuLink : styles.WhiteMenuLink
              }
            >
              Blogs
            </NavHashLink>
          </li>
          <li className={styles.MenuItem}>
            <NavHashLink
              smooth
              to="#Contact"
              className={
                this.state.navbar ? styles.BlackMenuLink : styles.WhiteMenuLink
              }
            >
              Contact
            </NavHashLink>
          </li>
        </ul>
      </div>
    );
  }
}

export default MenuBar;
