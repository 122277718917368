import React, { Component } from "react";
import styles from "./Css/HomePage.module.css";
import TextLoop from "react-text-loop";
import { FaLinkedinIn, FaFacebookF } from "react-icons/fa";
// import { RiInstagramFill } from "react-icons/ri";
import { AiOutlineGithub } from "react-icons/ai";
import { BsChevronDown } from "react-icons/bs";
import { ImTwitter } from "react-icons/im";
import { NavHashLink } from "react-router-hash-link";

class HomePage extends Component {
  render() {
    return (
      <div className={styles.HomePage} id="Home">
        <p className={styles.hw}>Hello & Welcome</p>
        <h1 className={styles.titles}>
          I Am{" "} 
          <TextLoop
            children={["Piyush Paliwal", "A Hacker", "A Developer", "A Gamer", "A CTF Player"]}
          />
        </h1>
        <ul className={styles.SocialAccounts}>
          {/* <li>
            <a
              className={styles.SocialAccountLink}
              href="https://www.facebook.com/piyush.paliwal.73/"
            >
              <FaFacebookF />
            </a>
          </li> */}
          {/* <li>
            <a
              className={styles.SocialAccountLink}
              href="https://www.instagram.com/_piyush_paliwal_/"
            >
              <RiInstagramFill />
            </a>
          </li> */}
          <li>
            <a
              className={styles.SocialAccountLink}
              href="https://github.com/PiyushThePal"
            >
              <AiOutlineGithub />
            </a>
          </li>
          <li>
            <a
              className={styles.SocialAccountLink}
              href="https://www.linkedin.com/in/piyush--paliwal/"
            >
              <FaLinkedinIn />
            </a>
          </li>
          <li>
            <a
              className={styles.SocialAccountLink}
              href="https://twitter.com/PiyushThePal"
            >
              <ImTwitter />
            </a>
          </li>
        </ul>
        <a href="Resume.pdf" className={styles.cv} download="Resume.pdf">
          Download CV
        </a>
        <footer className={styles.footericon}>
          <NavHashLink smooth to="#About" style={{ color: "white" }}>
            <BsChevronDown />
          </NavHashLink>
        </footer>
      </div>
    );
  }
}

export default HomePage;
