import React, { Component } from "react";
import HomePage from "./Pages/HomePage";
import About from "./Pages/About";
import Blogs from "./Pages/Blogs";
import Contact from "./Pages/Contact";
import MenuBar from "./Components/MenuBar";

class WebPages extends Component {
  render() {
    return (
      <div>
        <MenuBar />
        <HomePage />
        <About />
        <Blogs />
        <Contact />
      </div>
    );
  }
}

export default WebPages;
