import React, { Component } from "react";
import styles from "./Loader.module.css";
import CircleLoader from "react-spinners/CircleLoader";

export class Loader extends Component {
  render() {
    return (
      <div className={styles.loaderpage}>
        <CircleLoader size={100} color={"black"} />
      </div>
    );
  }
}

export default Loader;
